/**
*Created by Hensi Murcia
*/

html,
body {
  font-family: "Roboto", "Helvetica", sans-serif;
  height: 100%;
  margin: auto;
}

header {
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 10;
}

header a,
header h1 {
  flex: 1;
}
.btnOpen {
  /* float: left; */
  margin: auto 0;
  font-size: 18px;
  color: white;
  background: rgba(71, 67, 214, 0);
  border: none;
  /* padding: 20px; */
  cursor: pointer;
  height: 100%;
  min-width: 65px;
  text-shadow: 2px 2px 4px #000000;
  font-weight: bold;
  font-size: large;
}

.btnOpen:hover {
  background: rgb(0, 151, 221);
}

body {
  background: white;
  margin-right: auto;
  margin-left: auto;
}

.white-text {
  color: white;
  padding: 1px;
  padding-left: 2px;
}

#img-logo {
  width: 15%;
  border-radius: 50%;
  display: block; /*Important to center*/
  margin-right: auto;
  margin-left: auto;
}

input {
  width: 100%;
  padding: 12px;
  border: 2px solid #21b1f3;
  border-radius: 4px;
  margin: 5px 0;
  opacity: 0.85;
  display: inline-block;
  font-size: 17px;
  line-height: 20px;
  text-decoration: none; /* remove underline from anchors */
  box-sizing: border-box; /*Makes the button and inputs same width*/
  /*Or use: * {
    box-sizing: border-box; /*Makes the button and inputs same width*/
}

input:hover,
.btn-blue:hover,
.btn-lime:hover {
  opacity: 1;
}

main {
  margin-top: 65px;
}

.loader {
  position: absolute;
  left: 50%;
  top: 50%;
  z-index: 1;
  width: 150px;
  height: 150px;
  margin: -75px 0 0 -75px;
  border: 16px solid #f3f3f3;
  border-radius: 50%;
  border-top: 16px solid #3498db;
  width: 120px;
  height: 120px;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
  display: none;
}

.check {
  display: none;
  width: 100%;
  font-size: medium;
  /* background-color: red;      */
  border: 2px solid rgb(248, 223, 223);
  border-radius: 4px;
  box-sizing: border-box;
  padding-left: 5px;
  overflow: hidden;
  color: gray;
}

input[name="rememberMe"] {
  height: 20px;
  width: 20px;
}

#btnPhoneLogin {
  padding-left: 50px;
  background-image: url("../images/phone.svg");
  background-position: left top;
  background-repeat: no-repeat;
  background-size: contain;
  background-color: #00845b;
  color: white;
  cursor: pointer;
  margin-top: 30px;
}

#btnEmailLogin {
  padding-left: 50px;
  background-image: url("../images/email.png");
  background-position: left top;
  background-repeat: no-repeat;
  background-size: contain;
  background-color: #21b1f3;
  color: white;
  cursor: pointer;
  margin-top: 30px;
}

#loginInst {
  text-align: center;
}

#emailOptions,
#phoneOptions {
  display: none;
}

/* .horiz{
  margin-left: 10px;
  justify-content: start;
} */

#divPassInfo,
#btnLogin,
#btnRegister,
#etPassword {
  display: none;
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  display: none;
  position: relative;
  background-color: #f1f1f1;
  min-width: 160px;
  overflow: auto;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  font-size: 16px;
}

.dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  font-size: 16px;
}

.dropdown a:hover {
  background-color: #ddd;
}

.show {
  display: block;
}
.dropdown-content a.set {
  color: #0087ff;
}
#menuLang {
  color: white;
  background-image: url("../images/ic_down_arrow.svg");
  background-position: right;
  background-repeat: no-repeat;
  background-size: 10px;
  padding-right: 10px;
}
#btnTranslate {
  margin-right: 10px;
}
/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
