html, body {
  height: 100%;
  margin: auto;
  font-family: Roboto, Helvetica, sans-serif;
}

header {
  z-index: 10;
  width: 100%;
  position: fixed;
  top: 0;
}

header a, header h1 {
  flex: 1;
}

.btnOpen {
  color: #fff;
  cursor: pointer;
  text-shadow: 2px 2px 4px #000;
  background: #4743d600;
  border: none;
  min-width: 65px;
  height: 100%;
  margin: auto 0;
  font-size: large;
  font-weight: bold;
}

.btnOpen:hover {
  background: #0097dd;
}

body {
  background: #fff;
  margin-left: auto;
  margin-right: auto;
}

.white-text {
  color: #fff;
  padding: 1px 1px 1px 2px;
}

#img-logo {
  border-radius: 50%;
  width: 15%;
  margin-left: auto;
  margin-right: auto;
  display: block;
}

input {
  opacity: .85;
  box-sizing: border-box;
  border: 2px solid #21b1f3;
  border-radius: 4px;
  width: 100%;
  margin: 5px 0;
  padding: 12px;
  font-size: 17px;
  line-height: 20px;
  text-decoration: none;
  display: inline-block;
}

input:hover, .btn-blue:hover, .btn-lime:hover {
  opacity: 1;
}

main {
  margin-top: 65px;
}

.loader {
  z-index: 1;
  border: 16px solid #f3f3f3;
  border-top-color: #3498db;
  border-radius: 50%;
  width: 120px;
  height: 120px;
  margin: -75px 0 0 -75px;
  animation: 2s linear infinite spin;
  display: none;
  position: absolute;
  top: 50%;
  left: 50%;
}

.check {
  box-sizing: border-box;
  color: gray;
  border: 2px solid #f8dfdf;
  border-radius: 4px;
  width: 100%;
  padding-left: 5px;
  font-size: medium;
  display: none;
  overflow: hidden;
}

input[name="rememberMe"] {
  width: 20px;
  height: 20px;
}

#btnPhoneLogin {
  color: #fff;
  cursor: pointer;
  background-color: #00845b;
  background-image: url("phone.186810e9.svg");
  background-position: 0 0;
  background-repeat: no-repeat;
  background-size: contain;
  margin-top: 30px;
  padding-left: 50px;
}

#btnEmailLogin {
  color: #fff;
  cursor: pointer;
  background-color: #21b1f3;
  background-image: url("email.a1d4c5b2.png");
  background-position: 0 0;
  background-repeat: no-repeat;
  background-size: contain;
  margin-top: 30px;
  padding-left: 50px;
}

#loginInst {
  text-align: center;
}

#emailOptions, #phoneOptions, #divPassInfo, #btnLogin, #btnRegister, #etPassword {
  display: none;
}

.dropdown {
  display: inline-block;
  position: relative;
}

.dropdown-content {
  z-index: 1;
  background-color: #f1f1f1;
  min-width: 160px;
  font-size: 16px;
  display: none;
  position: relative;
  overflow: auto;
  box-shadow: 0 8px 16px #0003;
}

.dropdown-content a {
  color: #000;
  padding: 12px 16px;
  font-size: 16px;
  text-decoration: none;
  display: block;
}

.dropdown a:hover {
  background-color: #ddd;
}

.show {
  display: block;
}

.dropdown-content a.set {
  color: #0087ff;
}

#menuLang {
  color: #fff;
  background-image: url("ic_down_arrow.fb323e68.svg");
  background-position: 100%;
  background-repeat: no-repeat;
  background-size: 10px;
  padding-right: 10px;
}

#btnTranslate {
  margin-right: 10px;
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0);
  }

  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}

/*# sourceMappingURL=login.0b3a13bc.css.map */
